<template>
  <b-row>
      <b-col lg="12" sm="12">
          <b-overlay :show="loader">
              <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                  <template v-slot:projectNameSlot>
                  {{ }}
                  </template>
                  {{ $t('globalTrans.refund') }}
              </list-report-head>
              <b-table-simple v-if="app_info.app_refund.length > 0" bordered small>
                <thead>
                    <tr>
                        <b-th colspan="6" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                    </tr>
                    <tr class="bg-primary text-center text-align-center">
                        <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                        <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                        <th style="width:15%">{{ $t('externalTradeFair.refunded_date') }}</th>
                        <th style="width:15%">{{ $t('ditfAppTradeFairManages.cutting_amount') }}</th>
                        <th style="width:15%">{{ $t('externalTradeFair.refunded_amount') }}</th>
                        <th style="width:15%">{{ $t('globalTrans.comments') }}</th>
                    </tr>
                </thead>
                <b-tbody>
                    <b-tr v-for="(item, index2) in app_info.app_refund" :key="index2">
                        <b-td class="align-middle text-center">{{ $n(index2+1) }}</b-td>
                        <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                        <b-td class="align-middle text-center">{{ item.refund_date | dateFormat }}</b-td>
                        <b-td class="text-right">{{ $n(item.cutting_amount, { minimumFractionDigits: 2 }) }}</b-td>
                        <b-td class="text-right">{{ $n(item.refund_amount, { minimumFractionDigits: 2 }) }}</b-td>
                        <b-td class="text-center">{{ item.comments }}</b-td>
                    </b-tr>
                    <b-tr>
                              <b-td colspan="4" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                              <b-td class="text-right">{{ $n(getTotalRefund(app_info.app_refund), { minimumFractionDigits: 2 }) }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
          </b-overlay>
      </b-col>
  </b-row>
</template>
<script>
import { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  name: 'Form',
  props: ['id', 'circular_type'],
  components: {
      ListReportHead
  },
  data () {
      return {
          baseUrl: internationalTradeFairServiceBaseUrl,
          loader: false,
          app_info: {},
          stalls: []
      }
  },
  created () {
      if (this.id) {
          this.app_info = this.getRefundInfo()
          if (this.app_info.circular_type === 1) {
              this.mappingStallInfo(this.app_info.stall_pavilion)
          }
      }
  },
  methods: {
      mappingStallInfo (data) {
            const details = []
            // const booked = data.find(el => el.status === 2)
            const refunded = data.find(el => el.is_refund === 0)
            let stallArr = data
            // if (typeof booked !== 'undefined') {
            //     stallArr = data.filter(mItem => mItem.stall_cat_id !== booked.stall_cat_id && mItem.status === 1)
            // } else {
            //     stallArr = data.filter(mItem => mItem.status === 1)
            // }

            if (typeof refunded !== 'undefined') {
                stallArr = data.filter(mItem => mItem.stall_cat_id !== refunded.stall_cat_id && mItem.is_refund === 1)
            } else {
                stallArr = data.filter(mItem => mItem.is_refund === 1)
            }
            stallArr.forEach(item => {
                const Obj = details.find(el => el.stall_cat_id === item.stall_cat_id)
                if (typeof Obj === 'undefined') {
                    details.push({ stall_cat_id: item.stall_cat_id })
                }
            })
            const tA = details.map(el => {
                const newArry = data.filter(item => item.stall_cat_id === el.stall_cat_id)
                return Object.assign({}, el, { details: newArry })
            })
            this.stalls = tA
      },
      getFloorPrice (id) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
          return Obj !== undefined ? Obj.floor_price : ''
      },
      getStallCategoryName (id) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
          if (this.$i18n.locale === 'bn') {
          return Obj !== undefined ? Obj.text_bn : ''
          } else {
          return Obj !== undefined ? Obj.text_en : ''
          }
      },
      getStallCategoryNames (ids) {
        const names = ids.map(id => {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(
                item => parseInt(item.value) === parseInt(id)
            )

            if (Obj !== undefined) {
                return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
            } else {
                return ''
            }
        })

        // Join the resulting array with commas and return
        return names.filter(name => name !== '').join(', ')
    },
      getSecurityMoney (data) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
          const percentage = Obj !== undefined ? Obj.percentage : 0
          return (percentage * data) / 100
      },
      getQuotedPrice (items) {
          if (items) {
              let quotedPrice = 0
              items.filter(el => el.status === 1).map((item) => {
                  quotedPrice += parseInt(item.quoted_price)
              })
              return quotedPrice
          }
      },
      getTotalRefund (items) {
            const totalAmount = items.reduce((total, item) => total + parseFloat(item.refund_amount || 0), 0)
            return totalAmount
        },
      getTotalSMoney (items) {
          if (items) {
              let sMoney = 0
              const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
              const percentage = Obj !== undefined ? Obj.percentage : 0
              items.map(el => {
                  sMoney += (el.details[0].quoted_price * percentage) / 100
              })
              return sMoney
          }
      },
      getRefundInfo () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      }
  }
}
</script>
